import {createRouter, createWebHashHistory} from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: '/tongxing',
    name: 'tongxing',
    component: () => import ("../views/my/TongXing.vue")
  },
  {
    path: '/advice',
    name: 'advice',
    component: () => import ("../views/my/Advice.vue"),
    meta:{
      title: '我有话说',

    }
  },
  {
    path: '/advicepage',
    name: 'advicepage',
    component: () => import ("../views/my/AdviceList.vue"),
    meta:{
      title: '我有话说',
    }
  },
  {
    path: '/',
    redirect: '/baselandmanage'
  },
  {
    path: '/app/history/:id/:token',
    name: 'appdevicehistory',
    component: () => import ("../views/my/AppDeviceHistory.vue")
  },
  {
    path: '/app/sensor/history/:id',
    name: 'appsensorhistory',
    component: () => import ("../views/my/AppSensorHistory.vue")
  },
  {
    path: '/history/:id',
    name: 'devicehistory',
    component: () => import ("../views/my/DeviceHistory.vue")
  },
  {
    path: '/sensor/history/:id',
    name: 'sensorhistory',
    component: () => import ("../views/my/SensorHistory.vue")
  },
  {
    path: '/relay/history/:id',
    name: 'relayhistory',
    component: () => import ("../views/my/RelayHistory.vue")
  },
  {
    path: "/share/cameraimage/:id/:code",
    name: "cameraimageshare",
    meta: {
      title: '抓图列表'
    },
    component: () => import (
        /* webpackChunkName: "table" */
        "../views/my/CameraImageShare.vue")
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          title: '系统首页'
        },
        component: () => import (
            /* webpackChunkName: "dashboard" */
            "../views/Dashboard.vue")
      },
      {
        path: "/workermanage",
        name: "workermanage",
        meta: {
          title: '员工管理'
        },
        component: () => import (
            /* webpackChunkName: "table" */
            "../views/my/WorkerManage.vue")
      },
      {
        path: "/clockmanage",
        name: "clockmanage",
        meta: {
          title: '考勤管理'
        },
        component: () => import (
            /* webpackChunkName: "table" */
            "../views/my/ClockManage.vue")
      },
      {
        path: "/classplanmanage",
        name: "classplanmanage",
        meta: {
          title: '自动排班'
        },
        component: () => import (
            /* webpackChunkName: "table" */
            "../views/my/ClassPlanManage.vue")
      },
      {
        path: "/classplaninfo/:planID",
        name: "classplaninfo",
        meta: {
          title: '自动排班'
        },
        component: () => import (
            /* webpackChunkName: "table" */
            "../views/my/ClassPlanInfo.vue")
      },
      {
        path: "/classmanage",
        name: "classmanage",
        meta: {
          title: '班次管理'
        },
        component: () => import (
            /* webpackChunkName: "table" */
            "../views/my/ClassManage.vue")
      },
      {
        path: "/paysheetmanage",
        name: "paysheetmanage",
        meta: {
          title: '工资单管理'
        },
        component: () => import (
            /* webpackChunkName: "table" */
            "../views/my/PaysheetManage.vue")
      },
      {
        path: "/paysetting",
        name: "paysetting",
        meta: {
          title: '薪资设置'
        },
        component: () => import (
            /* webpackChunkName: "table" */
            "../views/my/PaySetting.vue")
      },
      {
        path: "/devicemanage",
        name: "devicemanage",
        meta: {
          title: '设备管理'
        },
        component: () => import (
            /* webpackChunkName: "table" */
            "../views/my/DeviceGroupManage2.vue")
      },


      {
        path: "/baselandmanage",
        name: "mybaselandmanage",
        meta: {
          title: '实况概览'
        },
        component: () => import (
            /* webpackChunkName: "table" */
            "../views/my/BaseAndLandManage.vue")
      },

      {
        path: "/plantplan",
        name: "plantplan",
        meta: {
          title: '种植计划'
        },
        component: () => import (
            /* webpackChunkName: "table" */
            "../views/my/PlantPlanManage.vue")
      },
      {
        path: "/worktask",
        name: "worktask",
        meta: {
          title: '农事任务'
        },
        component: () => import (
            /* webpackChunkName: "table" */
            "../views/my/WorkTaskManage.vue")
      },
      {
        path: "/workrecord",
        name: "workrecord",
        meta: {
          title: '农事记录'
        },
        component: () => import (
            /* webpackChunkName: "table" */
            "../views/my/WorkRecordManage.vue")
      },
      {
        path: "/cameramanage",
        name: "cameramanage",
        meta: {
          title: '监控管理'
        },
        component: () => import (
            /* webpackChunkName: "table" */
            "../views/my/CameraManage.vue")
      },
      {
        path: "/cameraimage/:id",
        name: "cameraimage",
        meta: {
          title: '抓图列表'
        },
        component: () => import (
            /* webpackChunkName: "table" */
            "../views/my/CameraImageManage.vue")
      },

      {
        path: "/group/:sn",
        name: "group",
        meta: {
          title: '设备详情'
        },
        component: () => import (
            /* webpackChunkName: "table" */
            "../views/my/DeviceGroupInfo.vue")
      },

      {
        path: "/trackmap/:sn",
        name: "trackmap",
        meta: {
          title: '轨迹图'
        },
        component: () => import (
            /* webpackChunkName: "table" */
            "../views/my/GPSTrackMap.vue")
      },
      {
        path: "/radarmap/:sn",
        name: "radarmap",
        meta: {
          title: '雷达图'
        },
        component: () => import (
            /* webpackChunkName: "table" */
            "../views/my/RadarMap.vue")
      },

      {
        path: "/table",
        name: "basetable",
        meta: {
          title: '表格'
        },
        component: () => import (
            /* webpackChunkName: "table" */
            "../views/BaseTable.vue")
      }, {
        path: "/charts",
        name: "basecharts",
        meta: {
          title: '图表'
        },
        component: () => import (
            /* webpackChunkName: "charts" */
            "../views/BaseCharts.vue")
      },

      {
        path: "/other",
        name: "other",
        meta: {
          title: '其他'
        },
        component: () => import (
            /* webpackChunkName: "table" */
            "../views/my/Other.vue")
      },

      {
        path: "/form",
        name: "baseform",
        meta: {
          title: '表单'
        },
        component: () => import (
            /* webpackChunkName: "form" */
            "../views/my/BaseForm.vue")
      }, {
        path: "/tabs",
        name: "tabs",
        meta: {
          title: 'tab标签'
        },
        component: () => import (
            /* webpackChunkName: "tabs" */
            "../views/Tabs.vue")
      }, {
        path: "/info/donate",
        name: "donate",
        meta: {
          title: '鼓励作者'
        },
        component: () => import (
            /* webpackChunkName: "donate" */
            "../views/Donate.vue")
      }, {
        path: "/permission",
        name: "permission",
        meta: {
          title: '权限管理',
          permission: true
        },
        component: () => import (
            /* webpackChunkName: "permission" */
            "../views/Permission.vue")
      }, {
        path: "/i18n",
        name: "i18n",
        meta: {
          title: '国际化语言'
        },
        component: () => import (
            /* webpackChunkName: "i18n" */
            "../views/I18n.vue")
      }, {
        path: "/upload",
        name: "upload",
        meta: {
          title: '上传插件'
        },
        component: () => import (
            /* webpackChunkName: "upload" */
            "../views/Upload.vue")
      }, {
        path: "/icon",
        name: "icon",
        meta: {
          title: '自定义图标'
        },
        component: () => import (
            /* webpackChunkName: "icon" */
            "../views/Icon.vue")
      }, {
        path: '/404',
        name: '404',
        meta: {
          title: '找不到页面'
        },
        component: () => import (/* webpackChunkName: "404" */
            '../views/404.vue')
      }, {
        path: '/403',
        name: '403',
        meta: {
          title: '没有权限'
        },
        component: () => import (/* webpackChunkName: "403" */
            '../views/403.vue')
      }
    ]
  }, {
    path: "/login",
    name: "Login",
    meta: {
      title: '登录'
    },
    component: () => import (
        /* webpackChunkName: "login" */
        "../views/Login.vue")
  },
  {
    path: "/about",
    name: "About",
    meta: {
      title: '关于'
    },
    component: () => import (
        /* webpackChunkName: "login" */
        "../views/my/About.vue")
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | 耘仓数据管理系统网站`;
 // const role = localStorage.getItem('ms_username');
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  //console.error(">>> ", to.path.substr(0, 6))
  if (!userInfo && to.path !== '/login' && to.path.substr(0,6) !== '/share'&& to.path.substr(0,4) !== '/app'
      && to.path.substr(0,9) !== '/tongxing'
      && to.path.substr(0,7) !== '/advice') {
    next('/login');
  } else if(userInfo && userInfo.company && userInfo.company.account == 'lyhz' && to.path !== '/advicepage' && to.path !== '/login'){
    next('/advicepage');
  }else if (to.meta.permission) {
    // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
    role === 'admin'
        ? next()
        : next('/403');
  } else {
    next();
  }
});

export default router;