import request from '../utils/request';

export default {
    userInfo:JSON.parse(localStorage.getItem("userInfo")),
   // path :"http://192.168.3.132:8083",
   // path :"http://127.0.0.1:8085",
   path :"http://yuncangai.com/iotapi",

    login (data){
        return request({
            url: this.path+'/sy/auth/login',
            method: 'post',
            data: JSON.stringify(data)
        });
    },

    baseAllGet (data){
        return request({
            url: this.path+'/sy/bl/base/all',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },

    landAllGet (data){
        return request({
            url: this.path+'/sy/bl/land/all',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },

    baseInfoGet (id){
        return request({
            url: this.path+'/sy/bl/base/single/'+id,
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
        });
    },

    landInfoGet (id){
        return request({
            url: this.path+'/sy/bl/land/single/'+id,
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
        });
    },

    plantPlanUnFinishListGetByLand (data){
        return request({
            url: this.path+'/sy/plant/plan/list/unfinishByLand',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },

    workTaskUnFinishListGetByPlan (data){
        return request({
            url: this.path+'/sy/plant/task/list/unfinishByPlan',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },

    plantPlanListGet (data){
        return request({
            url: this.path+'/sy/plant/plan/page',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },
    workTaskListGet (data){
        return request({
            url: this.path+'/sy/plant/task/page',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },
    workRecordListGet (data){
        return request({
            url: this.path+'/sy/plant/record/page',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },

    cameraListGet (data){
        return request({
            url: this.path+'/sy/camera/page',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },

    cameraImageListGet (data){
        return request({
            url: this.path+'/sy/camera/image/page',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },
    cameraImagePre (data){
        return request({
            url: this.path+'/sy/camera/image/pre',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },
    cameraImageNext (data){
        return request({
            url: this.path+'/sy/camera/image/next',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },
    cameraInfoGet (data){
        return request({
            url: this.path+'/sy/camera/info',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },

    cameraImageShare (data){
        return request({
            url: this.path+'/sy/camera/image/share',
            method: 'post',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            data: JSON.stringify(data)
        });
    },

    cameraImageListShareGet (data){
        return request({
            url: this.path+'/share/image/page',
            method: 'get',
            params: data
        });
    },

    cameraImagePreShareGet (data){
        return request({
            url: this.path+'/share/image/pre',
            method: 'get',
            params: data
        });
    },

    cameraImageNextShareGet (data){
        return request({
            url: this.path+'/share/image/next',
            method: 'get',
            params: data
        });
    },


    plantPlanAdd (data){
        return request({
            url: this.path+'/sy/plant/plan/add',
            method: 'post',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            data: JSON.stringify(data),
        });
    },
    plantPlanEdit (data){
        return request({
            url: this.path+'/sy/plant/plan/edit',
            method: 'post',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            data: JSON.stringify(data),
        });
    },
    plantPlanRemove (data){
        return request({
            url: this.path+'/sy/plant/plan/remove',
            method: 'post',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            data: JSON.stringify(data),
        });
    },


    workTaskAdd (data){
        return request({
            url: this.path+'/sy/plant/task/add',
            method: 'post',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            data: JSON.stringify(data),
        });
    },
    workTaskEdit (data){
        return request({
            url: this.path+'/sy/plant/task/edit',
            method: 'post',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            data: JSON.stringify(data),
        });
    },
    workTaskRemove (data){
        return request({
            url: this.path+'/sy/plant/task/remove',
            method: 'post',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            data: JSON.stringify(data),
        });
    },

    workRecordAdd (data){
        return request({
            url: this.path+'/sy/plant/record/add',
            method: 'post',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            data: JSON.stringify(data),
        });
    },
    workRecordEdit (data){
        return request({
            url: this.path+'/sy/plant/record/edit',
            method: 'post',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            data: JSON.stringify(data),
        });
    },
    workRecordRemove (data){
        return request({
            url: this.path+'/sy/plant/record/remove',
            method: 'post',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            data: JSON.stringify(data),
        });
    },


    appSyDeviceInfo (query, token){
        return request({
            url: this.path+'/sy/device/info',
            method: 'get',
            headers: {'token': this.userInfo==null ? token : this.userInfo.token, 'User-Agent':'app'},
            params: query
        });
    },
    appSyDeviceHistoryByID (query, token){
        return request({
            url: this.path+'/sy/device/history',
            method: 'get',
            headers: {'token': this.userInfo==null ? token : this.userInfo.token, 'User-Agent':'app'},
            params: query
        });
    },
    appSySensorHistoryByID (query, token){
        return request({
            url: this.path+'/sy/device/sensor/history',
            method: 'get',
            headers: {'token': this.userInfo==null ? token : this.userInfo.token, 'User-Agent':'app'},
            params: query
        });
    },


    syDeviceInfo (query){
        return request({
            url: this.path+'/sy/device/info',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: query
        });
    },
    syDeviceHistoryByID (query){
        return request({
            url: this.path+'/sy/device/history',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: query
        });
    },
    sySensorHistoryByID (query){
        return request({
            url: this.path+'/sy/device/sensor/history',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: query
        });
    },
    syRelayHistoryByID (query){
        return request({
            url: this.path+'/sy/device/relay/history',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: query
        });
    },
    syControlRelay (data){
        return request({
            url: this.path+'/sy/device/relay/control',
            method: 'post',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            data: JSON.stringify(data),
        });
    },
    syDeviceGroupList (query){
        return request({
            url: this.path+'/sy/device/group/all',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: query
        });
    },
    syDeviceGroupPage (query){
        return request({
            url: this.path+'/sy/device/group/device/page',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: query
        });
    },
    syDeviceList (query){
        return request({
            url: this.path+'/sy/device/all',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: query
        });
    },
    syGroupAbilityList (query){
        return request({
            url: this.path+'/sy/ability/select/list',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: query
        });
    },
    syGroupAndDeviceListByLandID (query){
        return request({
            url: this.path+'/sy/device/group/all/info/byLandID',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: query
        });
    },

    syGPSTrack (query){
        return request({
            url: this.path+'/sy/gps/list',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: query
        });
    },


    syWorkerAdd (data){
        return request({
            url: this.path+'/sy/user/add',
            method: 'post',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            data: JSON.stringify(data),
        });
    },
    syWorkerEdit (data){
        return request({
            url: this.path+'/sy/user/update',
            method: 'post',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            data: JSON.stringify(data),
        });
    },

    //重新下发到门禁
    syWorkerRefresh (data){
        return request({
            url: this.path+'/sy/user/refresh',
            method: 'post',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            data: JSON.stringify(data),
        });
    },

    syClassPlanListGet (data){
        return request({
            url: this.path+'/sy/work/classplan/page',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },

    syClassPlanInfoGet (data){
        return request({
            url: this.path+'/sy/work/classplan/info',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },

    syUserClassDelete (data){
        return request({
            url: this.path+'/sy/work/classplan/userclass/delete',
            method: 'post',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            data: JSON.stringify(data),
        });
    },

    syUserClassAdd (data){
        return request({
            url: this.path+'/sy/work/classplan/userclass/add',
            method: 'post',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            data: JSON.stringify(data),
        });
    },

    syPaysheetListGet (data){
        return request({
            url: this.path+'/sy/work/paysheet/page',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },

    syPaysheetSometimeReload (data){
        return request({
            url: this.path+'/sy/work/paysheet/reload',
            method: 'post',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            data: JSON.stringify(data),
        });
    },
    syPaysheetSometimeGet (data){
        return request({
            url: this.path+'/sy/work/paysheet/sometime',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },
    syWorkerListAllGet (data){
        return request({
            url: this.path+'/sy/user/list',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },
    syWorkerListGet (data){
        return request({
            url: this.path+'/sy/user/page',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },

    syClassListGet (data){
        return request({
            url: this.path+'/sy/work/class/page',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },

    syClassListAllGet (data){
        return request({
            url: this.path+'/sy/work/class/list',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },

    syWorkstatEdit (data){
        return request({
            url: this.path+'/sy/work/day/update',
            method: 'post',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            data: JSON.stringify(data),
        });
    },

    syWorkstatListGet (data){
        return request({
            url: this.path+'/sy/work/page',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },

    syWorkerPayEdit (data){
        return request({
            url: this.path+'/sy/work/pay/update',
            method: 'post',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            data: JSON.stringify(data),
        });
    },

    syWorkerPayInfoGet (data){
        return request({
            url: this.path+'/sy/work/pay/info',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: data
        });
    },

    radarLast (query){
        return request({
            url: this.path+'/sy/radar/last',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: query
        });
    },

    radarPage (query){
        return request({
            url: this.path+'/sy/radar/page',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: query
        });
    },
    radarInfo (query){
        return request({
            url: this.path+'/sy/radar/info',
            method: 'get',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            params: query
        });
    },



    //通行数据
    tongxing (data){
        return request({
            url: this.path+'/api/accessControl/community/getAccessRecordByPage',
            method: 'post',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            data: JSON.stringify(data)
        });
    },

    //龙游湖政 建议 二维码
    advice (data){
        return request({
            url: this.path+'/ly/hz/advice',
            method: 'post',
            headers: {'token': this.userInfo==null ? "" : this.userInfo.token},
            data: JSON.stringify(data)
        });
    },
    advicePage (data){
        return request({
            url: this.path+'/ly/hz/advice/page',
            method: 'get',
            params: data
        });
    },

    fetchData(query) {
        return request({
            url: './table.json',
            method: 'get',
            params: query
        });
    },
    login2 (data){
        return request({
            url: this.path+'/auth/login',
            method: 'post',
            data: JSON.stringify(data)
        });
    },

    addToken (info){
        this.userInfo = info;
       // request.defaults.headers.Authorization = 'Bearer '+(userInfo ? "" : userInfo.token)
    },
    getDeviceGroupList (query){
        console.error(">>>> ", this.userInfo)
        return request({
            url: this.path+'/test/device/group/all',
            method: 'get',
            headers: {'Authorization' : 'Bearer '+(this.userInfo==null ? "" : this.userInfo.token)},
            params: query
        });
    },
    getDeviceList (query){
        return request({
            url: this.path+'/test/device/all',
            method: 'get',
            headers: {'Authorization' : 'Bearer '+(this.userInfo==null ? "" : this.userInfo.token)},
            params: query
        });
    },
    getDeviceInfo (query){
        return request({
            url: this.path+'/test/device/info',
            method: 'get',
            headers: {'Authorization' : 'Bearer '+(this.userInfo==null ? "" : this.userInfo.token)},
            params: query
        });
    },
    getDeviceHistoryByID (query){
        return request({
            url: this.path+'/test/device/history',
            method: 'get',
            headers: {'Authorization' : 'Bearer '+(this.userInfo==null ? "" : this.userInfo.token)},
            params: query
        });
    },
    getSensorHistoryByID (query){
        return request({
            url: this.path+'/test/device/sensor/history',
            method: 'get',
            headers: {'Authorization' : 'Bearer '+(this.userInfo==null ? "" : this.userInfo.token)},
            params: query
        });
    },
    getRelayHistoryByID (query){
        return request({
            url: this.path+'/test/device/relay/history',
            method: 'get',
            headers: {'Authorization' : 'Bearer '+(this.userInfo==null ? "" : this.userInfo.token)},
            params: query
        });
    },
    controlRelay (query){
        return request({
            url: this.path+'/test/device/relay/control',
            method: 'post',
            headers: {'Authorization' : 'Bearer '+(this.userInfo==null ? "" : this.userInfo.token)},
            params: query
        });
    }

}

